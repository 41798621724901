<template>
  <div id="page">
    <Header :scroll="scroll">
      <div>
        <h1>{{ project.title }}</h1>
        <h2>
          <template v-for="line in subtitle" :key="line">{{ line }}<br /></template>
        </h2>
        <!--
        <div id="tags">
          <div v-for="tag in project.tags" :key="tag">{{ tag }}</div>
        </div>
      -->
      </div>
      <div>
        <h4>
          <template v-if="$store.state.language === 'EN'">
            <template v-if="project.type === 'semester'">Semester</template>
            <template v-else-if="project.type === 'bachelor'">Bachelor thesis</template>
            <template v-else>Private</template> project by
          </template>
          <template v-else>
            <template v-if="project.type === 'semester'">Semester-</template>
            <template v-else-if="project.type === 'bachelor'">Bachelor-</template>
            <template v-else>Privates </template>Projekt von
          </template>
          <template v-if="project.team.length > 0">
            {{ project.team.join(", ") }} &
          </template>Mikias Ehrmann.
        </h4>
        <div>
          <icon icon="arrow-down" />
          <h3 v-if="$store.state.language === 'EN'">... scroll to see the process & more.</h3>
          <h3 v-else>... scrolle, um mehr zum Projekt zu erfahren.</h3>
        </div>
      </div>
    </Header>

    <div id="content" :style="{ backgroundColor: project.color }">
      <div>
        <div>
          <p v-if="$store.state.language === 'EN'">Duration:</p>
          <p v-else>Dauer:</p>
          <b>{{ project.duration[$store.state.language] }}</b>
        </div>
        <div>
          <p v-if="$store.state.language === 'EN'">Main Tasks:</p>
          <p v-else>Hauptzuständigkeit:</p>
          <b>{{ project.maintask[$store.state.language] }}</b>
        </div>
        <div>
          <p v-if="$store.state.language === 'EN'">Further involvement:</p>
          <p v-else>Auch beteiligt an:</p>
          <b>{{ project.tasks }}</b>
        </div>
      </div>
      <div>
        <div id="progress">
          <component v-if="component" :is="component" @vue:mounted="setColoredElements"
            @openLink="href => openLink(href)" />
        </div>

        <div id="more">
          <div>
            <template v-if="projectIndex > 0">
              <h2 v-if="$store.state.language === 'EN'">Previous Project</h2>
              <h2 v-else>Vorheriges Projekt</h2>
              <GalleryCard :project="projects[projectIndex - 1]" />
            </template>
          </div>
          <div>
            <template v-if="projectIndex < (projects.length - 1)">
              <h2 v-if="$store.state.language === 'EN'">Next Project</h2>
              <h2 v-else>Nächstes Projekt</h2>
              <GalleryCard :project="projects[projectIndex + 1]" />
            </template>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
  import Footer from "@/components/Footer.vue"
  import GalleryCard from "@/components/GalleryCard.vue"
  import Header from "@/components/Header.vue"
  import { projects } from "@/data.js"
  import { defineAsyncComponent, markRaw } from 'vue'
  import scss from '@/../_variables.module.scss';

  export default {
    components: {
      Footer,
      GalleryCard,
      Header
    },

    emits: ['showNavbar', 'stars'],

    data() {
      return {
        projects: projects,
        project: projects.find(index => index.id === this.$route.params.content),
        projectIndex: projects.findIndex(index => index.id === this.$route.params.content),
        component: null
      }
    },

    props: {
      scroll: Number
    },

    computed: {
      subtitle() {
        return this.project.subtitle[this.$store.state.language].split(" <br /> ")
      }
    },

    methods: {
      setColoredElements() {
        const containers = document.getElementsByClassName("container")
        const prototypes = document.getElementsByClassName("frame")
        const screens = document.getElementsByClassName("screen")
        const elements = [...screens, ...prototypes, ...containers]

        for (const element of elements) {
          element.style.backgroundColor = this.project.color
        }
      },

      openLink(href) {
        window.open(href, '_blank')
      }
    },

    async mounted() {
      try {
        const comp = defineAsyncComponent(() => import(`@/components/content/${this.project.id}.vue`))
        this.component = markRaw(comp)

        document.documentElement.style.backgroundColor = this.project.color
      } catch {
        this.component = null
      }
    },

    async beforeUnmount() {
      document.documentElement.style.backgroundColor = scss.backgroundColor
    }
  }
</script>

<style lang="scss">
  @use "variables.module" as v;

  #page {
    #content {
      >div:first-child {
        background-color: rgba(black, 0.4);
        padding: calc(v.$viewport-padding*2.25) v.$viewport-padding;
        align-items: center;
        gap: 1.25em;

        >div {
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          max-width: 720px;

          b {
            text-align: right;
          }
        }
      }

      >div:last-child {
        background-color: rgba(black, 0.65);

        >* {
          width: 100%;
        }

        #progress {
          padding: calc(v.$viewport-padding*3.5) 0;
          gap: calc(v.$viewport-padding*3.5);
          align-items: center;
          z-index: 5;

          >div {
            width: 100%;
          }

          .group {
            max-width: 720px;
            gap: calc((v.$viewport-padding*2));
            align-items: center;

            p,
            h4 {
              width: 100%;

              a {
                color: rgba(v.$text-color, 0.6);
                font-style: italic;
                //line-break: anywhere;
              }
            }

            >div {
              align-items: center;
              gap: 1.5em;
            }
          }

          .references {
            width: fit-content;
            //max-width: 408px;
            gap: 1em !important;
            align-items: center;

            >button {
              width: 100%;
            }
          }

          .container {
            border-radius: 0.5em;
          }
        }

        .container {
          padding: v.$viewport-padding;
          align-items: flex-start !important;
          width: 100%;
          overflow: hidden;
          gap: 1.5em;

          &::after {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: inherit;
            background-color: rgba(black, 0.4);
            z-index: 0;
          }

          >* {
            z-index: 1;
          }
        }
      }

      #footer {
        z-index: 0;
      }
    }

    #title {
      h1 {
        text-shadow: rgba(black, 0.25) 0 0.075em;
        letter-spacing: -2px;
      }

      >div:first-child {
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        gap: 2.5em;

        >h2 {
          text-align: center;
          animation: delay 3s;
        }
      }

      #tags {
        flex-direction: row;
        gap: 0.5em;

        >div {
          background-color: rgba(black, 0.2);
          font-size: 0.75em;
          color: v.$text-color;
          border-radius: 4em;
          padding: 0.5em 1em;
        }
      }

      >div:last-child {
        align-items: center;
        gap: 2em;

        >h4 {
          text-align: center;
          max-width: 400px;
          opacity: 0.6;
        }

        >div {
          flex-direction: row;
          align-items: center;
          gap: 0.25em;

          svg {
            color: v.$text-color;
          }
        }
      }
    }

    #more {
      z-index: 1;
      flex-direction: row;
      gap: 0.5em;

      >div {
        width: 0;
        flex-grow: 1;
        gap: 0.75em;

        h2 {
          padding: 0 calc(v.$viewport-padding*0.5);
        }

        &:first-child>.card {
          border-radius: 0 0.5em 0.5em 0;
        }

        &:last-child {
          .card {
            border-radius: 0.5em 0 0 0.5em;
          }

          h2 {
            text-align: right;
          }
        }
      }
    }
  }
</style>