<template>
  <div id="privacy">
    <template v-if="$store.state.language === 'EN'">
      <div>
        <h2>Privacy Policy</h2>
        <p>The following information provides an overview of what happens to your personal data when you visit our
          website. Personal data is any data that can be used to identify you personally. Detailed information on the
          subject of data protection can be found listed in this text.</p>
      </div>

      <div>
        <h2>1. General Notice and mandatory Information</h2>
        <p>The operators of these pages take the protection of your personal data very seriously. We treat your personal
          data confidentially and in accordance with the statutory data protection regulations and this privacy policy.
        </p>
        <p>When you use this website, various personal data is collected. Personal data is data that can be used to
          identify you personally. This privacy policy explains what data we collect and what we use it for. It also
          explains how and for what purpose this is done.</p>
        <p>We would like to point out that data transmission over the Internet (e.g. when communicating by email) may be
          subject to security vulnerabilities. Complete protection of data against access by third parties is not
          possible.</p>
      </div>

      <div>
        <h4>Information on the controller</h4>
        <p>The controller responsible for data processing on this website is ...</p>

        <ul>
          <li>Mikias Ehrmann</li>
          <li>Lauterbadstr. 22</li>
          <li>72250 Freudenstadt</li>
          <li>Germany</li>
          <br />
          <li>{{ mail }}</li>
          <li>+49 151 12212952</li>
        </ul>

        <p>The controller is the natural or legal person who alone or jointly with others determines the purposes and
          means of the processing of personal data (e.g. names, e-mail addresses, etc.).</p>
      </div>

      <div>
        <h4>Revocation of your Consent to Data Processing</h4>
        <p>Many data processing operations are only possible with your express consent. You can withdraw your consent at
          any time. All you need to do is send an informal e-mail to the e-mail address given in the legal notice. The
          legality of the data processing carried out until the revocation remains unaffected by the revocation.</p>
      </div>

      <div>
        <h4>Right to lodge a Complaint with the competent supervisory Authority</h4>
        <p>In the event of breaches of data protection law, the data subject has the right to lodge a complaint with the
          competent supervisory authority. The competent supervisory authority for data protection issues is the state
          data protection officer of the federal state in which our company is based. A list of data protection officers
          and their contact details can be found at the following link: <a
            href="https://www.bfdi.bund.de/EN/Service/Anschriften/Laender/Laender-node.html"
            target="_blank">https://www.bfdi.bund.de/EN/Service/Anschriften/Laender/Laender-node.html</a>.
        </p>
      </div>

      <div>
        <h4>Right to Data Transferability</h4>
        <p>You have the right to have data that we process automatically on the basis of your consent or in fulfillment
          of
          a contract handed over to you or to a third party in a common, machine-readable format. If you request the
          direct transfer of the data to another controller, this will only take place if it is technically feasible.
        </p>
      </div>

      <div>
        <h4>Information, Blocking, Deletion</h4>
        <p>Within the framework of the applicable legal provisions, you have the right to free information about your
          stored personal data, its origin and recipient and the purpose of the data processing and, if applicable, a
          right to correction, blocking or deletion of this data at any time. You can contact us at any time at the
          address given in the legal notice if you have further questions on the subject of personal data.</p>
      </div>

      <div>
        <h4>Objection to advertising e-mails</h4>
        <p>We hereby object to the use of contact data published as part of our obligation to provide a legal notice for
          the purpose of sending unsolicited advertising and information material. The operators of this website
          expressly reserve the right to take legal action in the event of the unsolicited receipt of advertising
          information, such as spam e-mails.</p>
      </div>

      <div>
        <h2>2. Data Collection on this Website</h2>
        <h4>Cookies</h4>
        <p>Some of the Internet pages use cookies. Cookies do not damage your computer and do not contain viruses.
          Cookies
          are used to make our website more user-friendly, effective and secure. Cookies are small text files that are
          stored on your computer and saved by your browser. Most of the cookies we use are so-called "session cookies".
          They are automatically deleted at the end of your visit. Other cookies remain stored on your end device until
          you delete them. These cookies enable us to recognize your browser on your next visit.</p>
        <p>You can set your browser so that you are informed about the setting of cookies and only allow cookies in
          individual cases, exclude the acceptance of cookies for certain cases or in general and activate the automatic
          deletion of cookies when closing the browser. If cookies are deactivated, the functionality of this website
          may
          be restricted.</p>
        <p>Cookies that are required to carry out the electronic communication process or to provide certain functions
          that you have requested (e.g. shopping cart function) are stored on the basis of Art. 6 para. 1 lit. f GDPR.
          The
          website operator has a legitimate interest in the storage of cookies for the technically error-free and
          optimized provision of its services. Insofar as other cookies (e.g. cookies to analyze your surfing behavior)
          are stored, these are treated separately in this privacy policy.</p>
      </div>

      <div>
        <h4>Vercel (Hosting)</h4>
        <p>This website uses Vercel Inc.'s service "Vercel" for website hosting and display. Vercel is a cloud platform
          through
          which we provide our website. For more information please visit <a
            href="https://vercel.com/legal/privacy-policy" target="_blank">https://vercel.com/legal/privacy-policy</a>.
        </p>
      </div>

      <div>
        <h4>Figma (Prototypes)</h4>
        <p>This website uses Figma Inc.'s service "Figma" for sharing visual prototypes. Figma is a design platform
          through which we
          provide various interactive prototypes embedded in the presented study case project pages. For more
          information
          please visit <a href="https://www.figma.com/legal/privacy/"
            target="_blank">https://www.figma.com/legal/privacy/</a>.</p>
      </div>

      <div>
        <h4>ProtoPie (Prototypes)</h4>
        <p>This website uses Studio XID Korea Inc.'s service "ProtoPie" for sharing visual prototypes. ProtoPie is a
          design
          platform through which we provide various interactive prototypes embedded in the presented study case project
          pages. For more information please visit <a href="https://www.protopie.io/legal/privacy-policy"
            target="_blank">https://www.protopie.io/legal/privacy-policy</a>.</p>
      </div>

      <p>The data associated with your visit to our website is transmitted to the listed third-party services and
        processed or passed through their servers. This is necessary so that our website can successfully use those
        services. The listed third-party services are certified under the EU-US data protection agreement and are
        therefore obliged to comply with EU data protection regulations. Data processing is carried out on the basis of
        Art. 6 para. 1 lit. f GDPR.</p>
      <div>
        <h4>Server Log Files</h4>
        <p>The provider of the pages automatically collects and stores information in so-called server log files, which
          your browser automatically transmits to us. These informations include:</p>

        <ul>
          <li>Browser type and browser version</li>
          <li>Operating system used</li>
          <li>Referrer URL</li>
          <li>Host name of the accessing computer</li>
          <li>Time of the server request</li>
          <li>IP address</li>
        </ul>

        <p>This data is not merged with other data sources. The basis for data processing is Art. 6 para. 1 lit. f GDPR,
          which permits the processing of data for the fulfillment of a contract or pre-contractual measures.</p>
      </div>
    </template>
    <template v-else>
      <div>
        <h2>Datenschutzerklärung</h2>
        <p>Die folgenden Informationen geben einen Überblick darüber, was mit Ihren persönlichen Daten geschieht, wenn
          Sie unsere Website besuchen. Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz finden Sie in diesem
          Text.</p>
      </div>

      <div>
        <h2>1. Allgemeine Hinweise und Pflichtinformationen</h2>
        <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
          personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
        <p>Bei der Nutzung dieser Website werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
          sind Informationen, die dazu genutzt werden können, Ihre Identität zu ermitteln. Diese Datenschutzerklärung
          erläutert, welche Daten wir erheben und wofür wir sie verwenden. Sie erläutert auch, wie und zu welchem Zweck
          dies geschieht.</p>
        <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
          Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.</p>
      </div>

      <div>
        <h4>Hinweis zur verwantwortlichen Stelle</h4>
        <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist ...</p>

        <ul>
          <li>Mikias Ehrmann</li>
          <li>Lauterbadstr. 22</li>
          <li>72250 Freudenstadt</li>
          <br />
          <li>{{ mail }}</li>
          <li>+49 151 12212952</li>
        </ul>

        <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
          die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.)
          entscheidet.</p>
      </div>

      <div>
        <h4>Widerruf ihrer Einwilligung zur Datenverarbeitung</h4>
        <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Eine einmal erteilte
          Einwilligung können Sie jederzeit widerrufen. Hierzu genügt eine formlose Mitteilung per E-Mail an uns. Die
          Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt von dem Widerruf unberührt.</p>
      </div>

      <div>
        <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>
        <p>Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen
          Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
          Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der
          Datenschutzbeauftragten sowie deren Kontaktdaten finden Sie unter folgendem Link: <a
            href="https://www.bfdi.bund.de/DE/Service/Anschriften/anschriften_table.html"
            target="_blank">https://www.bfdi.bund.de/DE/Service/Anschriften/anschriften_table.html</a>.
        </p>
      </div>

      <div>
        <h4>Recht auf Datenübertragbarkeit</h4>
        <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
          automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Soweit Sie die direkte Übermittlung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit dies technisch machbar ist.
        </p>
      </div>

      <div>
        <h4>Auskunft, Sperrung, Löschung</h4>
        <p>Sie haben im Rahmen der gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre
          gespeicherten Daten, deren Herkunft und Empfänger sowie den Zweck der Datenverarbeitung und ggf. ein Recht auf
          Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
          Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p>
      </div>

      <div>
        <h4>Widerspruch gegen Werbe-Mails</h4>
        <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht
          ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
          Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
          Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>
      </div>

      <div>
        <h2>2. Datenerfassung auf diesem Internetauftritt</h2>
        <h4>Cookies</h4>
        <p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden
          an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer
          zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser
          speichert. Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach
          Ende Ihres
          Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen.
          Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</p>
        <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies
          nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das
          automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann
          die Funktionalität dieser Website eingeschränkt sein.</p>
        <p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter,
          von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
          Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B.
          Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung
          gesondert behandelt.</p>
      </div>

      <div>
        <h4>Vercel (Hosting)</h4>
        <p>Diese Website nutzt den Dienst „Vercel“ von Vercel Inc. für das Hosting und die Anzeige der Website. Vercel
          ist eine Cloud-Plattform, über die wir unsere Website bereitstellen. Für weitere Informationen besuchen Sie
          bitte <a href="https://vercel.com/legal/privacy-policy"
            target="_blank">https://vercel.com/legal/privacy-policy</a>.
        </p>
      </div>

      <div>
        <h4>Figma (Prototypen)</h4>
        <p>Diese Website nutzt den Dienst "Figma" von Figma Inc. für die Bereitstellung visueller Prototypen. Figma ist
          eine Design-Plattform über die wir verschiedene interaktive Prototypen zur Verfügung stellen, die in die
          Seiten der vorgestellten Projekte eingebettet sind. Für weitere Informationen besuchen Sie bitte <a
            href="https://www.figma.com/de-de/legal/privacy"
            target="_blank">https://www.figma.com/de-de/legal/privacy</a>.</p>
      </div>

      <div>
        <h4>ProtoPie (Prototypen)</h4>
        <p>Diese Website nutzt den Dienst "ProtoPie" von Studio XID Korea Inc. für die Bereitstellung visueller
          Prototypen. ProtoPie ist
          eine Design-Plattform über die wir verschiedene interaktive Prototypen zur Verfügung stellen, die in die
          Seiten der vorgestellten Projekte eingebettet sind. Für weitere Informationen besuchen Sie bitte <a
            href="https://www.protopie.io/legal/privacy-policy"
            target="_blank">https://www.protopie.io/legal/privacy-policy</a>.</p>
      </div>

      <p>Die Daten, die mit Ihrem Besuch auf unserer Website verbunden sind, werden an die aufgeführten Drittdienste
        übermittelt und verarbeitet oder durch deren Server geleitet. Dies ist notwendig, damit unsere Website diese
        Dienste erfolgreich nutzen kann. Die aufgeführten Drittdienste sind im Rahmen des EU-US-Datenschutzabkommens
        zertifiziert und sind daher zur Einhaltung der EU-Datenschutzbestimmungen verpflichtet. Die Datenverarbeitung
        erfolgt auf der Grundlage von Art. 6 Abs. 1 lit. f DSGVO.</p>
      <div>
        <h4>Server-Log-Dateien</h4>
        <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien,
          die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>

        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
          <li>IP-Adresse</li>
        </ul>

        <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Grundlage für die
          Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags
          oder vorvertraglicher Maßnahmen gestattet.</p>
      </div>
    </template>
  </div>
</template>

<script>
  import { mail } from "@/data.js"

  export default {
    data() {
      return {
        mail: mail
      }
    }
  }
</script>

<style lang="scss" scoped>
  @use "variables.module" as v;

  #privacy {
    padding: calc(v.$viewport-padding*3.5) 0;
    align-items: center;
    width: 100%;
    gap: calc((v.$viewport-padding*2));

    >* {
      width: 100%;
      max-width: 720px;
    }

    >div {
      gap: 1.5em;

      a {
        color: rgba(v.$text-color, 0.6);
        font-style: italic;
        //line-break: anywhere;
      }
    }
  }
</style>