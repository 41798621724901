<template>
  <Transition name="fade" appear>
    <div id="image" v-if="alt && $route.params.content">
      <img :src="'/img/content/' + $route.params.content + '/' + src" @load="imgLoaded = true" :style="{opacity: imgLoaded ? 1 : 0}"/>
      <h5>{{ alt }}</h5>
    </div>
    <img v-else-if="$route.params.content" :src="'/img/content/' + $route.params.content + '/' + src" @load="imgLoaded = true" :style="{opacity: imgLoaded ? 1 : 0}"/>
    <img v-else :src="'/img/' + src" @load="imgLoaded = true" :style="{opacity: imgLoaded ? 1 : 0}"/>
  </Transition>
</template>

<script>
  export default {
    props: {
      src: String,
      alt: String
    },

    data() {
      return {
        imgLoaded: false
      };
    }
  }
</script>

<style lang="scss" scoped>
  @use "variables.module" as v;

  img {
    background: none;
    object-fit: contain;
    border-radius: 0.25em;
    max-width: 100%;
    opacity: 0;
    transition: all 0.5s ease-in;
  }

  #image {
    gap: 0.75em !important;
    height: fit-content;
    align-items: center;

    img {
      width: 100%;
    }

    >h5 {
      width: 100%;
    }
  }
</style>