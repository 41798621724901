import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import HomeView from "@/views/HomeView.vue"
import ContentView from "@/views/ContentView.vue"
import AboutView from "@/views/AboutView.vue"
import LegalView from "@/views/LegalView.vue";
import PrivacyView from "@/views/PrivacyView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: '/project/:content',
    name: 'Content',
    component: ContentView,
    props: true
  },
  {
    path: "/about",
    name: "About",
    component: AboutView,
  },
  {
    path: "/legal",
    name: "Legal",
    component: LegalView,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyView,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
