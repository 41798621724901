<template>
  <div>
    <Header :scroll="scroll">
      <div>
        <h1 v-if="$store.state.language === 'DE'">Über Mich</h1>
        <h1 v-else-if="$store.state.language === 'EN'">About Me</h1>
      </div>
      <div>
        <icon icon="arrow-down" />
        <h3 v-if="$store.state.language === 'DE'">... scrolle, um mehr zu erfahren!</h3>
        <h3 v-else-if="$store.state.language === 'EN'">... scroll to see what makes me tick!</h3>
      </div>
    </Header>

    <Image id="backgroundImage" src="me.png"/>

    <div id="content">
      <div v-if="$store.state.language === 'EN'">
        <h1>Hi, I am Mikias.</h1>
        <h2>A software designer and developer (web & frontend focused) from the Nothern Black Forest!</h2>
      </div>
      <div v-else>
        <h1>Hi, Ich bin Mikias.</h1>
        <h2>Ein Software-Designer & -Entwickler (mit Fokus auf Web & Frontend) aus dem Nordschwarzwald!</h2>
      </div>

      <!--
      <div id="backgroundImage2">
        <Image src="me.png" />
      </div>-->

      <div class="group">
        <template v-if="$store.state.language === 'EN'">
          <div>
            <h4>Design AND Development?</h4>
            <p>When I was still in school, I discovered my interest in programming by building websites for a computer
              science class. The following year, however, I quickly realized that it wasn't the right fit for me. It was
              way too mathematical and involved a lot of tedious theory that I just couldn't enjoy (my
              grade in that class just dropped from being great straight to 0 (!)). After school, I did some preliminary
              studies at <a href="https://talentstudio.org/" target="_blank">talentstudio Stuttgart</a> to test the
              waters in areas like interaction design, but I still wanted to write code.
            </p>
          </div>
          <p>Eventually, I started my studies in “Digital Product Design & Development” (formerly
            “Internet of Things”) at
            the <a href="https://www.hfg-gmuend.de/" target="_blank">HfG Schwäbisch Gmünd</a>, a smaller and rather
            unique
            study program, to get the best of both worlds. I spent my internship semester over the winter 2022/23 at a
            small software company based in Freiburg called <a href="https://www.geospin.de/"
              target="_blank">Geospin</a>,
            so I could get a better impression of what it’s like to work on an active and ongoing digital service,
            together with a small team of developers, while filling the design role. In February 2024, I completed my
            studies with a Bachelor of Arts.</p>
        </template>
        <template v-else-if="$store.state.language === 'DE'">
          <div>
            <h4>Design UND Entwicklung?</h4>
            <p>Mein Interesse am Programmieren entdeckte ich bereits in der Schule durch das
              Wahlfach Informatik, wo ich
              zum ersten Mal mit HTML und JavaScript in Berührung kam. Allerdings merkte ich schon im darauffolgenden
              Semester, dass ich mich mit der plötzlichen Menge an trockener Theorie und Mathematik überhaupt nicht
              anfreunden konnte (meine Note fiel direkt von "sehr gut" auf "0" (!)). Nach der Schule folgte dann mein
              Vorpraktikum im <a href="https://talentstudio.org/" target="_blank">talentstudio Stuttgart</a>, um mich in
              Bereichen wie Interaktionsdesign auszuprobieren. Aber der Code fehlte mir dabei irgendwie.</p>
          </div>
          <p>Um das Beste aus beiden Welten zu vereinen, habe ich schließlich mein Studium in
            „Digital Product Design &
            Development“ (ehemals „Internet of Things“) an der <a href="https://www.hfg-gmuend.de/" target="_blank">HfG
              Schwäbisch Gmünd</a> begonnen, einem kleineren und eher
            ungewöhnlichen Studiengang. Ich verbrachte mein Praxissemester im Winter 2022/23 zudem bei einem kleinen
            Freiburger Softwareunternehmen namens <a href="https://www.geospin.de/" target="_blank">Geospin</a>, um
            einen besseren Eindruck davon zu bekommen, wie es ist,
            zusammen mit einem Team von Entwicklern an einem aktiven digitalen Service zu arbeiten und dabei
            gleichzeitig
            die Rolle des Designers einzunehmen. Im Februar 2024 habe ich mein Studium mit einem Bachelor of Arts
            abgeschlossen.</p>
        </template>
      </div>

      <div class="group">
        <div v-if="$store.state.language === 'EN'">
          <h4>What I care about</h4>
          <p>I've always enjoyed learning about new topics in my projects, but I especially like figuring out which
            building blocks are the most important and how to combine them together into a holistic product that meets
            the user's needs. When I do this, it's important to me that every piece is placed with purpose and plays a
            meaningful role in the overall logic of the design, no matter if it's serious or playful. But most
            importantly, I care about bringing concepts one step closer to reality in a hands-on way and building
            things that "just work"!</p>
        </div>
        <div v-else>
          <h4>Was mich antreibt</h4>
          <p>Es hat mir schon immer Spaß gemacht, mich in neue Themen einzuarbeiten, aber besonders mag ich es,
            herauszufinden, welche Bausteine am wichtigsten sind und wie man sie zu einem ganzheitlichen Produkt
            kombinieren kann, das den Bedürfnissen der Benutzer entspricht. Dabei ist es mir wichtig, dass jedes
            Element sinnvoll eingesetzt wird und eine sinnvolle Rolle in der Gesamtlogik des Designs spielt. Vor allem
            aber geht es mir darum, Konzepte konkret an die Realität heranzuführen und Dinge zu bauen, die tatsächlich
            funktionieren!</p>
        </div>
      </div>

      <ImgRow>
        <div id="images">
          <Image src="bow.jpg" />
          <Image src="guitar.jpg" />
        </div>
      </ImgRow>

      <div class="group">
        <p v-if="$store.state.language === 'EN'">Outside of work and the occasional code I write for fun, I like to unwind with archery
          and
          music (I play
          piano and guitar). But sometimes I also just enjoy playing video games that let you to immerse yourself in
          good world-building and character writing.</p>
        <p v-else>Neben meiner Arbeit und gelegentlichem Code, den ich zum Spaß schreibe, verbringe ich meine Zeit gerne
          mit
          Bogenschießen und Musik (ich spiele Klavier und Gitarre). Manchmal spiele ich zum Entspannen aber auch
          einfach nur Videospiele, bei denen man tief in gutes Worldbuilding und Charaktere eintauchen kann.</p>
      </div>


      <Email/>
      <Footer/>
    </div>
  </div>
</template>

<script>
  import { projects, mail } from "../data.js"
  import Image from "@/components/Image.vue"
  import ImgRow from "@/components/ImgRow.vue"
  import Footer from "@/components/Footer.vue"
  import Email from "@/components/Email.vue"
  import Header from "@/components/Header.vue"

  export default {
    components: {
      Image,
      Footer,
      ImgRow,
      Email,
      Header
    },

    data() {
      return {
        projects: projects,
        mail: mail
      }
    },

    props: {
      scroll: Number
    }
  }
</script>

<style lang="scss" scoped>
  @use "variables.module" as v;

  #backgroundImage {
    position: absolute;
    z-index: -1;
    transform: translateY(-20%);
    top: 0;
    left: v.$viewport-padding + 0.75em;
    width: 65%;
    filter: brightness(75%);
  }

  /*
  #backgroundImage2 {
    position: absolute;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    top: 0;
    left: 0;

    >img {
      top: -100vh;
      width: 65%;
      margin-left: v.$viewport-padding + 0.75em;
      transform: translateY(-20%);
    }
  }
  */

  #content {
    >* {
      width: 100%;
    }

    >div:first-child {
      gap: 2.5em;
      max-width: 720px;

      h1 {
        font-size: 3.5em;
        text-shadow: none;
      }

      h2 {
        line-height: 105%;
      }
    }

    //background-color: v.$background-color;
    padding-top: calc(v.$viewport-padding*3.5);
    gap: calc(v.$viewport-padding*3.5);
    align-items: center;
    z-index: 5;
    //box-shadow: inset 0 10em 10em -10em rgba(v.$text-color, 0.1);

    >div {
      width: 100%;
    }

    .group {
      max-width: 720px;
      gap: calc((v.$viewport-padding*2));
      align-items: center;

      p,
      h4 {
        width: 100%;

        a {
          color: rgba(v.$text-color, 0.6);
          font-style: italic;
          //line-break: anywhere;
        }
      }

      >div {
        align-items: center;
        gap: 1.5em;
      }
    }

    #footer {
      z-index: 0;
    }
  }

  #images {
    background: none;
    gap: 0.5em;

    >img {
      height: 100%;
    }

    >img:first-child {
      border-radius: 0 0.5em 0.5em 0;
    }

    >img:last-child {
      border-radius: 0.5em 0 0 0.5em;
    }
  }

  #title {
    min-height: 100vh;
    width: 100%;

    div:first-child {
      height: fit-content;
      padding: 2.25em 2.5em;
      border-radius: 0.5em;
      justify-content: center;
      //max-width: 720px;
      gap: 1.5em;

      >h1 {
        text-align: center;
        text-shadow: rgba(v.$text-color, 0.4) 0 0.075em;
      }
    }

    div:last-child {
      position: absolute;
      bottom: calc(v.$viewport-padding*0.75);
      padding: 0.25em 0.75em;
      padding-left: 0.5em;
      border-radius: 2em;
      flex-direction: row;
      align-items: center;
      gap: 0.25em;

      svg {
        color: v.$text-color;
      }
    }
  }
</style>