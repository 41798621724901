<template>
  <div id="email">
    <template v-if="$store.state.language === 'EN'">
      <h2>Want to say Hello?</h2>
      <p>... then please feel free to get in touch with me via my email!</p>
    </template>
    <template v-else>
      <h2>Möchtest Du Hallo sagen?</h2>
      <p>... dann kontaktiere mich gerne einfach über meine Mail!</p>
    </template>
    <a style="text-decoration: none;" :href="'mailto:' + mail" target="_blank">
      <Button style="width: 100%;" class="primary" icon="envelope" target="_blank">{{ mail }}</Button>
    </a>
  </div>
</template>

<script>
  import Button from "@/components/Button.vue"
  import { mail } from "@/data.js"

  export default {
    components: {
      Button
    },

    data() {
      return {
        mail: mail
      }
    },
  }
</script>

<style lang="scss" scoped>
  @use "variables.module" as v;

  #email {
    max-width: 400px;
    padding: v.$viewport-padding;
    align-items: center;
    width: 100%;
    gap: 1.5em;
    border-radius: 0.5em;
    align-self: center;
    //background: linear-gradient(120deg, v.$accent-color, 60%, #8841df);

    &::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: inherit;
      background-color: rgba(v.$text-color, 0.1);
      z-index: 0;
    }

    >* {
      z-index: 1;
      text-align: center;
      width: 100%;
    }
  }
</style>