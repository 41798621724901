<template>
  <Transition name="fade" mode="out-in">
    <div id="mobileScreen" v-if="viewport === 'portrait-primary'">
      <h4>This portfolio is best viewed in landscape mode!</h4>
      <p>A mobile version is still work-in-progress. Images, prototypes, layout and text might therefore not be shown
        correctly if you proceed on your current device.</p>
      <Button class="secondary" @click="viewport = ''">I understand, still continue!</Button>
    </div>

    <div id="app" v-else
      :style="{ background: $route.name === 'Content' ? projects.find(index => index.id === $route.params.content).color : 'none' }">
      <div id="contacts">
        <Transition name="fade">
          <div v-if="$route.name === 'Home' || $route.name === 'About'" class="links">
            <a href="https://github.com/hfg-mikias-e" target="_blank"><Button
                icon="fa-brands fa-github">GitHub</Button></a>
            <span></span>
            <Button @click="scrollToElement('email')" icon="envelope">Email</Button>
          </div>
        </Transition>
        <Transition name="fade">
          <Button id="back" v-if="$route.name !== 'Home'" @click="$router.push('/')" icon="arrow-left">Home</Button>
        </Transition>
        <Button id="lang" icon="language" @click="switchLanguage">{{ otherLang }}</Button>
      </div>
      <div id="route">
        <router-view v-slot="{ Component, route }" style="height: 100%;">
          <Transition name="fade" mode="out-in">
            <component :is="Component" :key="route.fullPath + otherLang" @scroll="scrollTop" :scroll="scroll" />
          </Transition>
        </router-view>
      </div>
    </div>
  </Transition>
</template>

<script>
  import Button from "@/components/Button.vue"
  import { projects, mail } from "./data.js"

  export default {
    components: {
      Button
    },

    data() {
      return {
        projects: projects,
        viewport: "",
        mail: mail,
        scroll: 0,
      }
    },

    computed: {
      otherLang() {
        if (this.$store.state.language === 'DE') {
          return 'EN'
        }
        return 'DE'
      }
    },

    methods: {
      checkOrientation() {
        this.viewport = screen.orientation.type
      },

      scrollTop(e) {
        this.scroll = e.target.scrollTop
      },

      switchLanguage() {
        this.$store.dispatch("setLang", this.otherLang)
      },

      scrollToElement(el) {
        document.getElementById(el).scrollIntoView({behavior: "smooth"})
      }
    },

    created() {
      this.checkOrientation()
      window.addEventListener("orientationchange", this.checkOrientation)
    },

    beforeUnmount() {
      window.removeEventListener("orientationchange", this.checkOrientation)
    }
  }
</script>

<style lang="scss">
  @use "variables.module" as v;
  @import "./style.scss";

  #mobileScreen {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    padding: v.$viewport-padding;
    align-items: center;
    justify-content: center;
    gap: v.$viewport-padding;

    >p,
    h4,
    button {
      max-width: 600px;
    }

    >button {
      width: 100%;
      margin-top: 0.5em;
    }
  }

  #route {
    height: 100vh;
    width: 100%;

    >div {
      z-index: 1;
      overflow-x: hidden;
    }
  }

  #contacts {
    position: fixed;
    height: fit-content;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    top: v.$viewport-padding*0.5;
    left: 0;
    z-index: 10;

    >div,
    >button {
      height: fit-content;
      padding: 0 v.$viewport-padding;
    }

    >button {
      position: absolute;
      top: 0;
    }

    #back {
      left: 0;
    }

    #lang {
      right: 0;
    }

    a {
      text-decoration: none;
    }
  }

  .links {
    flex-direction: row;
    align-items: center;
    font-size: 1em;
    gap: 0.75em;

    button {
      display: flex;
      flex-direction: row-reverse;
      font-size: 1em;
    }

    >span {
      background-color: rgba(v.$text-color, 0.25);
      width: 2px;
      height: 1em;
    }
  }
</style>