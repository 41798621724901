<template>
  <div id="footer">
    <p id="note">Code by Mikias Ehrmann.</p>
    <div class="links">
      <Button v-if="$store.state.language === 'DE'" @click="$router.push('/privacy')">Datenschutz</Button>
      <Button v-else-if="$store.state.language === 'EN'" @click="$router.push('/privacy')">Privacy</Button>
      <span></span>
      <Button v-if="$store.state.language === 'DE'" @click="$router.push('/legal')">Impressum</Button>
      <Button v-else-if="$store.state.language === 'EN'" @click="$router.push('/legal')">Legal Notice</Button>
    </div>
  </div>
</template>

<script>
  import Button from "@/components/Button.vue"
  import { mail } from "@/data.js"

  export default {
    components: {
      Button
    },

    data() {
      return {
        mail: mail,
        footerHeight: 0
      }
    },

    mounted() {
      this.footerHeight = document.getElementById("footer").clientHeight
    }
  }
</script>

<style lang="scss" scoped>
  @use "variables.module" as v;

  #footer {
    align-items: center;
    padding: calc(v.$viewport-padding*0.75) v.$viewport-padding;
    padding-bottom: v.$viewport-padding*0.5;
    justify-content: space-between;
    flex-direction: row;
    bottom: 0;
    gap: 1.5em;
    width: 100%;
  }

  #note {
    opacity: 0.6;
    font-size: 0.75em;
  }

  @supports (animation-timeline: scroll()) {
    @keyframes fade-in {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    #footer {
      animation: fade-in auto linear both;
      animation-timeline: scroll();
      animation-range: calc(100% - 5em) 100%;
    }
  }
</style>