<template>
  <div id="title" :style="{opacity: opacity}">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      text: String,
      scroll: Number
    },

    data() {
      return {
        opacity: 1
      }
    },

    watch: {
      scroll() {
        this.opacity = (document.getElementById("title").clientHeight - this.scroll) / (0.5*document.getElementById("title").clientHeight) - 1
      }
    }
  }
</script>

<style lang="scss" scoped>
  @use "variables.module" as v;

  #title {
    top: 0;
    position: sticky;
    min-height: calc(100% - (v.$viewport-padding * 1));
    margin: 0 auto;
    padding: v.$viewport-padding;
    padding-bottom: calc(v.$viewport-padding * 0.5);
    width: fit-content;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    button {
      pointer-events: auto;
    }

    h2 {
      font-size: 2.5em;
    }
  }
</style>