<template>
  <div id="legals">
    <template v-if="$store.state.language === 'EN'">
      <div>
        <h2>Legal Notice</h2>
        <p>This Legal Notice complies with the German laws under § 5 TMG and § 18 MStV.</p>
      </div>

      <div>
        <b>Responsible for content:</b>
        <ul>
          <li>Mikias Ehrmann</li>
          <li>Lauterbadstr. 22</li>
          <li>72250 Freudenstadt</li>
          <li>Germany</li>
          <br />
          <li>{{ mail }}</li>
        </ul>
      </div>


      <div>
        <h4>Liability for Content</h4>
        <p>The contents of our website have been created with the greatest possible care. However, we cannot guarantee
          the
          contents' accuracy, completeness, or topicality. According to Section 7, paragraph 1 of the TMG
          (Telemediengesetz - German Telemedia Act), we as service providers are liable for our content on these pages
          by
          general laws. However, according to Sections 8 to 10 of the TMG, we service providers are not obliged to
          monitor
          external information transmitted or stored or investigate circumstances pointing to illegal activity.
          Obligations to remove or block the use of information under general laws remain unaffected. However, a
          liability
          in this regard is only possible from the moment of knowledge of a specific infringement. Upon notification of
          such violations, we will remove the content immediately.</p>
      </div>

      <div>
        <h4>Liability for Links</h4>
        <p>Our website contains links to external websites, over whose contents we have no control. Therefore, we cannot
          accept any liability for these external contents. The respective provider or operator of the websites is
          always
          responsible for the contents of the linked pages. The linked pages were checked for possible legal violations
          at
          the time of linking. Illegal contents were not identified at the time of linking. However, permanent
          monitoring
          of the contents of the linked pages is not reasonable without specific indications of a violation. Upon
          notification of violations, we will remove such links immediately.</p>
      </div>

      <div>
        <h4>Copyright</h4>
        <p>The contents and works on these pages created by the site operator are subject to German copyright law. The
          duplication, processing, distribution, and any kind of utilization outside the limits of copyright require the
          written consent of the respective author or creator. Downloads and copies of these pages are only permitted
          for
          private, non-commercial use. In so far as the contents on this site were not created by the operator, the
          copyrights of third parties are respected. In particular, third-party content is marked as such. Should you
          become aware of a copyright infringement, please inform us accordingly. Upon notification of violations, we
          will
          remove such contents immediately.</p>
      </div>
    </template>
    <template v-else>
      <div>
        <h2>Impressum</h2>
        <p>Dieses Impressum entspricht den deutschen Gesetzen gemäß § 5 TMG und § 18 MStV.</p>
      </div>

      <div>
        <b>Verantwortlich für den Inhalt:</b>
        <ul>
          <li>Mikias Ehrmann</li>
          <li>Lauterbadstr. 22</li>
          <li>72250 Freudenstadt</li>
          <br />
          <li>{{ mail }}</li>
        </ul>
      </div>


      <div>
        <h4>Haftung für Inhalte</h4>
        <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
          Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1
          TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
          sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen
          zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
          unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
          Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.</p>
      </div>

      <div>
        <h4>Haftung für Links</h4>
        <p>Unsere Seite enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
          Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
          Seiten
          ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
      </div>

      <div>
        <h4>Urheberrecht</h4>
        <p>Die durch den Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
          Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwendung außerhalb der Grenzen
          des Urheberrechtes bedürfen der die schriftliche Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads
          und Kopien dieser Seite sind nur für den für privaten, nicht kommerziellen Gebrauch gestattet. Soweit die
          Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
          Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie auf eine Urheberrechtsverletzung
          aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
          wir derartige Inhalte unverzüglich entfernen.</p>
      </div>
    </template>
  </div>
</template>

<script>
  import { mail } from "@/data.js"

  export default {
    data() {
      return {
        mail: mail
      }
    }
  }
</script>

<style lang="scss" scoped>
  @use "variables.module" as v;

  #legals {
    padding: calc(v.$viewport-padding*3.5) 0;
    align-items: center;
    width: 100%;
    gap: calc((v.$viewport-padding*2));

    >* {
      width: 100%;
      max-width: 720px;
    }

    >div {
      gap: 1.5em;

      a {
        color: rgba(v.$text-color, 0.6);
        font-style: italic;
        //line-break: anywhere;
      }
    }
  }
</style>