<template>
  <div id="animatedTitle">
    <h1 v-for="(letter, index) in singleLetters" :key="letter" :style="{ animationDelay: (index * 0.2) + 0.5 + 's' }">{{ letter }}
    </h1>
  </div>
</template>

<script>
  export default {
    props: {
      text: String
    },

    data() {
      return {
        singleLetters: this.text.split("")
      }
    },
  }
</script>

<style lang="scss" scoped>
  @use "variables.module" as v;

  #animatedTitle {
    height: 7em;
    overflow: hidden;
    display: flex;
    flex-direction: row;

    >h1 {
      animation: slide-up 0.5s forwards;
      margin-top: 1em;
      font-weight: 500;
    }
  }

  @keyframes slide-up {
  100% {
    margin-top: 0;
  }
}
</style>