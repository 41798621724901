<template>
  <button>
    <icon v-if="icon" :icon="icon" :class="{larger: icon === 'language'}"/>
    <slot></slot>
  </button>
</template>

<script>
  export default {
    props: {
      icon: String,
    }
  }
</script>

<style lang="scss">
  @use "variables.module" as v;

  button {
    width: fit-content;
    height: fit-content;
    border-radius: 0.5em;
    box-sizing: border-box;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: v.$text-color;
    border: none;
    text-decoration: none;
    vertical-align: middle;
    gap: 0.5em;
    transition: all 0.1s ease-in;
    cursor: pointer;
    font-family: "Jersey 15";
    font-size: 1.25em;

    &:enabled:hover {
      cursor: pointer;
      color: v.$accent-color;
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }

    &.primary {
      padding: 0.75em;
      color: v.$background-color;
      background-color: v.$text-color;

      &:enabled:hover {
        color: v.$background-color;
        background-color: v.$accent-color;
      }
    }

    &.secondary {
      padding: 0.75em;
      border: 1px solid v.$text-color;

      &:enabled:hover {
        border: 1px solid v.$accent-color;
        background-color: rgba(v.$accent-color, 0.4);
      }
    }

    svg,
    path {
      height: 1.25em;

      &.larger {
        height: 1.4em;
      }
    }

    img {
      width: 1.25em;
      background: none;
    }
  }
</style>