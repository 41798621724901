import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  state: {
    language: "DE",
  },
  mutations: {
    SET_LANG(state, data) {
      state.language = data;
      console.log(state.language)
    },
  },
  actions: {
    setLang(state, data) {
      state.commit("SET_LANG", data);
    },
  },
  plugins: [vuexLocal.plugin],
});
