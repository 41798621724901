<template>
  <Transition name="fade" appear>
    <div id="imageRow" ref="imageRow">
      <slot></slot>
      <h5 v-if="alt">{{ alt }}</h5>
    </div>
  </Transition>
</template>

<script>
  export default {
    props: {
      src: String,
      alt: String
    },

    data() {
      return {
        sameHeight: 100
      }
    },

    mounted() {
      const imageRows = this.$refs.imageRow.children
      this.adjustRow(imageRows)

      addEventListener("resize", () => {
        this.adjustRow(imageRows)
      })
    },

    methods: {
      async adjustRow(imageRows) {
        for (const row of imageRows) {
          if (row.localName === "div") {
            let imagesTotalWidth = 0

            for (const img of row.children) {
              if (img.complete) {
                imagesTotalWidth = this.addHeight(row, img, imagesTotalWidth)
              } else {
                img.onload = () => {
                  imagesTotalWidth = this.addHeight(row, img, imagesTotalWidth)
                }
              }
            }
          }
        }
      },

      addHeight(row, img, priorWidth) {
        // bring all images to the same height and calculate the total width
        const sameWidth = this.sameHeight * (img.naturalWidth / img.naturalHeight)
        const newWidth = priorWidth + sameWidth

        // give row the right height by the according ratio
        row.style.height = row.clientWidth * (this.sameHeight / newWidth) + "px"
        return newWidth
      }
    }
  }
</script>

<style lang="scss">
  @use "variables.module" as v;

  #imageRow {
    gap: 0 !important;

    >div {
      display: flex;
      flex-direction: row;
      background-color: white;
      width: 100%;

      img {
        border-radius: 0;
        height: 100%;
      }
    }

    >h5 {
      top: 100%;
      margin-top: 0.75em;
      position: absolute;
      padding: 0 v.$viewport-padding;
    }
  }
</style>