export const projects = [
  {
    id: "digital-twinzer",
    title: "Digital TWINzer",
    subtitle: {DE: "Weinberg-Monitoring für <br /> den Winzer der Zukunft.", EN: "Vineyard Monitoring for <br /> Winemakers of the Future."},
    tags: ["Prototyping", "React", "Teachable Machine", "Figma", "Wireframing", "Information Architecture", "User Research"],
    type: "semester",
    duration: {DE: "März 2023 – Juni 2023", EN: "March 2023 – June 2023"},
    team: ["Jakob Seeger", "Konrad Kleisinger", "Fabian Buchenberger"],
    maintask: {DE: "Technischer Prototyp als Proof of Concept", EN: "Technical prototype as a Proof of Concept"},
    tasks: "Concept, Wireframing",
    color: "#245955",
  },
  {
    id: "wheretowatch",
    title: "WhereToWatch",
    subtitle: {DE: "Finde den richtigen Streamingservice <br /> für deine Serien.", EN: "Find the right Streaming Service <br /> to see your Shows."},
    tags: ["Prototyping", "React Native", "MVP", "REST APIs", "Hybrid App"],
    type: "semester",
    duration: {DE: "Mai 2023 – Juni 2023", EN: "May 2023 – June 2023"},
    team: ["Matteo Scholz", "Lars Grasmann"],
    maintask: {DE: "Implementierung der Suchfunktion", EN: "Prototype the Search Function"},
    tasks: "Concept, Infrastructure, Styling",
    color: "#243E59",
  },
  {
    id: "tycoonline",
    title: "TYCOONline",
    subtitle: {DE: "Spiele Tycoon mit <br /> Deinen Freunden online.", EN: "An Online Card Game to <br /> play Tycoon with your Friends."},
    tags: ["Prototyping", "Vue", "MVP", "Websockets", "Progressive Web App", "Express Backend", "Information Architecture", "Screen Design"],
    type: "private",
    duration: {DE: "Februar 2024 – April 2024", EN: "February 2024 – April 2024"},
    team: [],
    maintask: {DE: "Funktionaler MVP", EN: "Functional MVP"},
    tasks: "Concept, Styling",
    color: "#592428",
  },
  {
    id: "bites",
    title: "Bites",
    subtitle: {DE: "Deine Microlearning-App für <br /> den Erwerb von Future Skills.", EN: "Your Microlearning App for <br /> acquiring Future Skills."},
    tags: ["Prototyping", "Vue", "MVP", "Progressive Web App", "Information Architecture", "Screen Design", "Micro Interactions / Animation", "User Research"],
    type: "bachelor",
    duration: {DE: "Oktober 2023 – Februar 2024", EN: "October 2023 – February 2024"},
    team: ["Konrad Kleisinger", "Paul Mang"],
    maintask: {DE: "Funktionaler Prototyp", EN: "Functional Prototype"},
    tasks: "Concept, Styling, Wireraming",
    color: "#4E3D66",
  }
]

export const mail = "mikias.ehrmann@gmx.de"